@import 'node_modules/bootstrap/scss/functions.scss';
@import 'node_modules/bootstrap/scss/variables.scss';
@import 'node_modules/bootstrap/scss/mixins.scss';

// Row
//
// Rows contain your columns.
.b4-row {
    @include make-row();
    box-sizing: border-box;

    > .col,
    > [class*="col-"] {
        box-sizing: border-box;
    }
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();
